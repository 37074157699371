import React from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import { Icons } from '../../../assets';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../../shared/state';

const BackButton: React.FC = (): JSX.Element => {
  const navigation = useNavigation();
  const [Colors] = useGlobalState('colors');

  return (
    <TouchableOpacity
      style={[styles.backButton,{backgroundColor: Colors.mainColorDark.color}]}
      onPress={() => navigation.goBack()}
    >
      <Image source={Icons.backArrowIcon} style={styles.iconContainer}/>
    </TouchableOpacity>
  );
};

export default BackButton;

const styles = StyleSheet.create({
  backButton: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#8e8f90',
    opacity: 0.95,
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0
    }
  },
  iconContainer: {
    height: '50%',
    width: '50%',
    resizeMode: 'contain'
  }
});
