import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ImageBackground } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import { Icons, background } from "../../assets";
import { Fonts, Styles } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import ScannerCoreService from "../../shared/services/core-services";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import CustomTextInput from "../../components/Text/CustomTextInput";
import CustomCheckBox from "../../components/Checkbox/CustomCheckBox";
import ProductService from "../../shared/services/products-service";

const CreationModal: React.FC = (route): JSX.Element => {
  const { params } = route.route;
  // State variables
  const [Colors] = useGlobalState("colors");
  const [insoleCreated, setInsoleCreated] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [shoeSize, setShoeSize] = useState<string>("");
  const [contourLeadingSizeSystem, setContourLeadingSizeSystem] = useState<string | null>("");
  const [weight, setWeight] = useState<number>(35);
  const [insoleType, setInsoleType] = useState<string>("standard-sport");
  const [dataProtectionChecked, setDataProtectionChecked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [contours, setContours] = useState<any>(null);
  const [sizes, setSizes] = useState<React.ReactNode[]>([]);

  // Other necessary state and functions
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const navigation = useNavigation();
  const recommendation = params.recommendation;
  const sizeRecommendation = params.sizeRecommendation;
  const [currentScan] = useGlobalState("currentScan");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentUser, setCurrentUser] = useGlobalState("currentUser");
  const [newScanState] = useGlobalState("newScanState");
  const [contourItems, setContourItems] = useState([]);

  useEffect(() => {
    const fetchContourIDs = async () => {
      try {
        const response = await ProductService.getInsoleContourIDs();
        const contours = response.results;

        setContours(contours);

        const items = contours.map((contour) => <Picker.Item key={contour.contour_id} label={contour.name} value={contour.contour_id} />);
        const firstInsoleTypeItem = contours[0].contour_id;
        setInsoleType(firstInsoleTypeItem);
        setContourItems(items);
      } catch (error) {
        console.error("Error fetching insole contour IDs:", error);
      }
    };

    fetchContourIDs();
  }, []); // This effect runs only once on mount

  useEffect(() => {
    let sizesArray: JSX.Element[] = [<Picker.Item label="" value="" key="" />];
    let newShoeSize = ""; // Default value if not found
    let newContourLeadingSizeSystem = null;
  
    if (contours && contours.length > 0) {
      if (contours.length === 1) {
        // If only one contour, directly iterate over its sizes
        contours[0].sizes.forEach((size) => {
          sizesArray.push(
            <Picker.Item label={size.shoe_size} value={size.shoe_size} key={size.shoe_size} />
          );
          if (Object.values(size).includes(sizeRecommendation[contours[0].leading_system].toString())) {
            newShoeSize = sizeRecommendation[contours[0].leading_system];
          }
        });
        newContourLeadingSizeSystem = contours[0].leading_system
      } else {
        contours.forEach((contour) => {
          if (contour.contour_id === insoleType) {
            contour.sizes.forEach((size) => {
              sizesArray.push(
                <Picker.Item label={size.shoe_size} value={size.shoe_size} key={size.shoe_size} />
              );
              if (sizeRecommendation && Object.values(size).includes(sizeRecommendation[contour.leading_system].toString())) {
                newShoeSize = sizeRecommendation[contour.leading_system];
              }
              // Only update the leading system if it's not already set
              if (!newContourLeadingSizeSystem || newContourLeadingSizeSystem != contour.leading_system) {
                newContourLeadingSizeSystem = contour.leading_system;
              }
            });
          };
        });
      }
    }
    setSizes(sizesArray);
    setShoeSize(newShoeSize);
    setContourLeadingSizeSystem(newContourLeadingSizeSystem);
  }, [contours, insoleType]);

  const register = () => {
    ScannerCoreService.postRegistration(email, dataProtectionChecked)
      .then(() => {
        if (currentScan) {
          assignScanToUser();
        }
      })
      .catch((error) => {
        setErrorMessage(i18n.t("auth.emailExists"));
      });
  };
  const assignScanToUser = () => {
    ScannerCoreService.postRegistrationWithScan(email, currentScan.customer_uuid)
      .then(() => {
        const data = {
          onefid: email,
          shoe_size: shoeSize,
          contour_id: insoleType,
          weight: weight,
          config: currentScanner.insoleConfig && currentScanner.insoleConfig,
          sizing_system: contourLeadingSizeSystem,
          age_group: "adults",
          gender: currentUser ? currentUser.gender : newScanState.gender === "male" ? "m" : "f",
          auto_print: currentScanner.auto_print ? currentScanner.auto_print : false,
        };
        ProductService.postInsoleCreation(data).then((response) => {
          setInsoleCreated(true);
        });
        setCurrentUser((prevState: any) => ({
          ...prevState,
          email: email,
        }));
      })
      .catch((error) => {
        setErrorMessage(i18n.t("auth.emailExists"));
      });
  };

  const validateEmail = (email) => {
    // Use a regular expression to validate the email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const getCheckBoxValue = () => {
    setDataProtectionChecked(!dataProtectionChecked);
  };

  const handleSubmission = () => {
    let error = "";
    if (!dataProtectionChecked) {
      error = "Please accept the terms and conditions.";
    } else if (!validateEmail(email)) {
      error = "Please enter a valid email address.";
    }

    if (error) {
      setErrorMessage(error);
    } else {
      setErrorMessage(null);
      register();
    }
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={false}>
        <View style={styles.modalWrapper}>
          <TouchableOpacity style={styles.closeButton} onPress={() => navigation.goBack(null)}>
            <Image source={Icons.closeIconSquare} style={styles.iconContainer} />
          </TouchableOpacity>
          {!insoleCreated ? (
            <View style={styles.formWrapper}>
              <Text style={styles.titleText}>{i18n.t("createInsole")}</Text>
              <Image source={{ uri: recommendation.image }} style={styles.insoleImage} />
              <View
                style={{
                  width: "95%",
                  height: "10%",
                  marginTop: "2%",
                  paddingLeft: "0",
                  paddingRight: "0",
                  paddingTop: "2%",
                  backgroundColor: "#f0eff1",
                  borderRadius: 6,
                  justifyContent: "space-between",
                  alignSelf: "center",
                }}
              >
                <CustomTextInput parentCallback={(text) => setEmail(text)} errorValidation="Enter Valid email" placeholder={i18n.t("auth.emailPlaceholder")} />
              </View>
              <View style={styles.inputsWrapper}>
                <Text style={styles.labelText}>{i18n.t("insoleType")}:</Text>
                <Picker style={styles.picker} selectedValue={insoleType} onValueChange={(itemValue) => setInsoleType(itemValue)}>
                  {contourItems}
                </Picker>
              </View>
              <View style={styles.inputsWrapper}>
                <Text style={styles.labelText}>{i18n.t("shoeSize")}:</Text>
                <Picker style={styles.picker} selectedValue={shoeSize} onValueChange={(itemValue) => setShoeSize(itemValue)}>
                  {sizes}
                </Picker>
              </View>
              <View style={styles.inputsWrapper}>
                <Text style={styles.labelText}>{i18n.t("weight")}:</Text>
                <Picker style={styles.picker} selectedValue={weight} onValueChange={(itemValue) => setWeight(itemValue)}>
                  {Array.from({ length: 24 }, (_, i) => (
                    <Picker.Item label={(i * 5 + 35).toString() + " kg"} value={i * 5 + 35} key={i} />
                  ))}
                </Picker>
              </View>
              <View style={styles.checkboxField}>
                <CustomCheckBox text={i18n.t("auth.termsAndConditions")[0]} submitted={dataProtectionChecked} parentCallback={getCheckBoxValue} termsAndConditions={dataProtectionChecked} />
              </View>
              <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={handleSubmission}>
                <Text style={styles.buttonText}> {i18n.t("auth.confirm")} </Text>
              </TouchableOpacity>
              {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}
            </View>
          ) : (
            <View style={styles.contentWrapper}>
              <Image style={styles.imageContainer} source={Icons.confirmEmailIcon} />
              <Text style={[styles.successText, Fonts.mLight, Colors.black, Fonts.center]}>
                {i18n.t("auth.succesRegisterMessage")[0]}
                <Text style={Fonts.mBlack}>{i18n.t("auth.succesRegisterMessage")[1]}</Text> {i18n.t("auth.succesRegisterMessage")[2]}
              </Text>
              <Text style={[styles.successText, Fonts.mLight, Colors.black, Fonts.center]}>{i18n.t("insoleCreation")}</Text>

              <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={() => navigation.navigate("MainApp", { screen: "Measurements" })}>
                <Text style={styles.buttonText}> {i18n.t("mainScreen")} </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    width: "7%",
    height: "12%",
    position: "absolute",
    right: -5,
    top: 0,
    zIndex: 10,
    paddingTop: "3.5%",
    paddingLeft: "2%",
  },
  iconContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  modalWrapper: {
    flex: 1,
    width: "57%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  formWrapper: {
    width: "90%",
    height: "90%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  contentWrapper: {
    width: "45%",
    height: "75%",
    alignSelf: "center",
    justifyContent: "space-evenly",
  },
  inputsWrapper: {
    width: "95%",
    height: "10%",
    marginTop: "2%",
    paddingLeft: "4%",
    paddingRight: "2%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  labelText: {
    width: "13%",
  },
  picker: {
    width: "90%",
    height: "50%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    marginLeft: "5%",
  },
  checkboxField: {
    width: "95%",
    height: "10%",
    marginTop: "2%",
    paddingRight: "2%",
    borderRadius: 6,
    alignItems: "flex-start",
    flexDirection: "row",
    alignSelf: "center",
  },
  insoleImage: {
    height: "18%",
    width: "20%",
    resizeMode: "contain",
    alignSelf: "center",
  },
  imageContainer: {
    resizeMode: "contain",
    height: 100,
    width: 150,
    alignSelf: "center",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  successText: {
    fontSize: 29,
    fontWeight: "300",
    lineHeight: 50,
  },
  error: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
    color: "red",
    marginTop: "4%",
  },
});

export default CreationModal;
