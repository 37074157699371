import React, { useEffect, useState } from 'react';
import { Image, View, StyleSheet, TouchableOpacity } from 'react-native';
import { greenDot, Icons, redDot } from '../../assets';
import ModalWrapper from '../../components/ModalWrapper/ModalWrapper';
import TextTitle from '../../components/Text/TextTitle';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles/common.styles';

const NewScanModal: React.FC = (): JSX.Element => {
  const [, setCurrentScreen] = useGlobalState('currentScreen');
  const [currentUser, setCurrentUser] = useGlobalState('currentUser');
  const [, setCurrentMeasurements] = useGlobalState('currentMeasurements');
  const [, setMatchedProducts] = useGlobalState('matchedProducts');
  const [, setAllProducts] = useGlobalState('allProducts');
  const [, setCurrentScan] = useGlobalState('currentScan');
  const [userToken, setUserToken] = useGlobalState('userToken');
  const [language] = useGlobalState('language');
  const [userInfo, setUserInfo] = useState<string | undefined>(undefined);

  const i18n = I18N(language);
  const navigation = useNavigation();
  const logout = () => {
    setCurrentUser(undefined);
    setUserToken(undefined)
    setCurrentMeasurements(undefined)
    setMatchedProducts(undefined)
    setCurrentScan(undefined)
    setAllProducts([])
    setCurrentScreen('Home');
    navigation.navigate('Home');
  };

  useEffect(() => {
    setUserInfo(userToken
      ? currentUser.firstName.length > 0 && currentUser.lastName.length > 0
        ? `${currentUser.firstName} ${currentUser.lastName}`
        : currentUser.email
      : currentUser && currentUser.employeeNumber
        ? currentUser.employeeNumber
        : i18n.t('new user'))
  }, [])


  return (
    <ModalWrapper title={currentUser ? i18n.t('new scan') : i18n.t('new user')}>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.iconsContainer}>
            <Image style={styles.DotWrapper} source={currentUser ? greenDot : redDot} />
            <Image style={styles.iconWrapper} source={Icons.loginIconOutlined} />
          </View>
          <TextTitle
            text={userInfo}
            fontFamily="Montserrat-Regular"
            fontSize={15}
            fontWeight="normal"
            uppercase={false}
          />
          <View style={styles.divider} />
          <TextTitle
            text={currentUser ? i18n.t('new scan modal logged in user') : i18n.t('new scan modal not logged in user')}
            fontFamily="Montserrat-Regular"
            fontSize={14}
            fontWeight="normal"
            uppercase={false}
          />
          <TouchableOpacity style={Styles.submitButtonDark} onPress={() => navigation.navigate('NewScanGenderSelection', { resetState: true })}>
            <TextTitle fontSize={12} fontWeight="normal" text={i18n.t('new scan')} color='white' />
          </TouchableOpacity>
          <TouchableOpacity style={Styles.submitButtonLight} onPress={() => currentUser ? logout() : navigation.replace('RegisterModal')}>
            <TextTitle fontSize={12} fontWeight="normal" text={currentUser ? i18n.t('auth.logOut') : i18n.t('save result')} />
          </TouchableOpacity>
        </View>
      </View>
    </ModalWrapper>
  );
};

export default NewScanModal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center'
  },
  content: {
    width: '40%',
    height: '80%',
    paddingTop: '5%',
    justifyContent: 'space-evenly',
  },
  iconsContainer: {
    width: '40%',
    height: '25%',
    alignSelf: 'center',

  },
  DotWrapper: {
    alignSelf: 'flex-end',
    width: '12%',
    height: '12%',
    marginRight: '10%',
    resizeMode: 'contain'
  },
  iconWrapper: {
    width: '75%',
    height: '70%',
    resizeMode: 'contain',
    alignSelf: 'center'
  },
  divider: {
    width: '60%',
    alignSelf: 'center',
    borderTopColor: '#707070',
    borderTopWidth: 1
  },
});