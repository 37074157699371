import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import { ImageBackground, StyleSheet, ActivityIndicator, Image, Text, TouchableOpacity } from 'react-native';
import { background, Icons } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles';
import SelectionCard from '../Scan/SelectionCard';


const Catalog: React.FC = (): JSX.Element => {
  const [language] = useGlobalState("language");
  const [matchedProducts] = useGlobalState("matchedProducts");
  const [currentScanner] = useGlobalState('currentScanner');
  const [categoryCards, setCategoryCards] = useState<any>([]);
  const [Colors] = useGlobalState('colors');

  const i18n = I18N(language);
  const navigation = useNavigation();

  const nav = (route: any) => {
    navigation.push(route);
  };

  useEffect(() => {
    if (matchedProducts && Object.keys(matchedProducts).length > 0 && categoryCards.length === 0) {
      currentScanner.components.map((catalog: any, index: number) => {
        setCategoryCards((prevState: any) => [...prevState, <SelectionCard key={index} value={catalog.caption} route={catalog.route} image={catalog.image} onPress={nav} />]);
      })
    }
    if (matchedProducts && Object.keys(matchedProducts).length == 0 && categoryCards.length === 0) {
      currentScanner.components.map((catalog: any, index: number) => {
        if (!catalog.caption.includes('Filter')) {
          setCategoryCards((prevState: any) => [...prevState, <SelectionCard key={index} value={catalog.caption} route={catalog.route} image={catalog.image} onPress={nav} />]);
        }
      })
    }
    return () => setCategoryCards([])
  }, [matchedProducts]);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={false} title={i18n.t('catalog')}>
        <View style={Styles.contentWrapper}>
          {matchedProducts && Object.keys(matchedProducts).length == 0 && categoryCards.length === 0
            ? <View style={styles.noProductsWrapper}>
              <Image source={Icons.NotFoundImage} style={{ width: '50%', height: '50%', resizeMode: 'contain', alignSelf: 'center' }}></Image>
              <Text style={styles.loadingText}>{i18n.t('noProductFoundMessage')}</Text>
              <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.navigate('NewScanGenderSelection', { resetState: true })}>
                <Text style={[styles.buttonText, { color: '#000000' }]}>{i18n.t('new scan')}</Text>
              </TouchableOpacity>
            </View>
            :
            categoryCards.length > 0
              ? <View style={styles.cardWrapper}>{categoryCards}</View>
              : <View style={styles.loadingWrapper}>
                <Text style={styles.loadingText}>{i18n.t('loadProductMessage')}</Text>
                <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
              </View>}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default Catalog;

const styles = StyleSheet.create({
  cardWrapper: {
    width: '60%',
    height: '90%',
    alignSelf: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '0.4%',
    paddingLeft: '0.2%'
  },
  titeText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    fontWeight: '900',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  loadingText: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 19,
    fontWeight: '600',
    textAlign: 'center',
    alignSelf: 'center'
  },
  noProductsWrapper: {
    width: '100%',
    height: '65%',
    justifyContent: 'space-around',
    alignSelf: 'center',
  },
  loadingWrapper: {
    width: '100%',
    height: '35%',
    justifyContent: 'space-around',
    alignSelf: 'center',
  },
  buttonText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 12,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
});
