import React from 'react';
import { PixelRatio, Platform, StyleSheet, Text, View } from 'react-native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';

type Props = {
  caption: string
  categories: {
    name: string
    value: number
  }[]
  valueCount: number
  values: {
    left: number
    right: number
  }
}

const MeasurementScale: React.FC<Props> = ({ caption, categories, valueCount, values }: Props): JSX.Element => {
  const [ language ] = useGlobalState('language');
  const [Colors] = useGlobalState('colors');

  const i18n = I18N(language);
  const rows: string[] = Object.keys(values);
//  scale Array[0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.6, 2.8, 3, 3.2, 3.4, 3.6, 3.8, 4,]
  const scale: number[] = Array.from(Array((valueCount - 1) * 5 + 1).keys()).map(key => parseFloat(((key / 5)).toFixed(1)));
  return <View style={styles.scaleContainer}>
    <Text style={styles.scaleCaption}>{caption.toUpperCase()}</Text>
    <View style={styles.rowContainer}>
      {rows && rows.map((row: string, index: number) => {
        return (
          <View style={styles.scaleRow} key={index}>
            <View style={styles.scaleRowName}>
              <Text adjustsFontSizeToFit allowFontScaling style={styles.scaleRowNameText}>{i18n.t(`MeasurementScale.${row}`).toUpperCase()}</Text>
            </View>
            <View style={styles.scaleRowScaleContainer}>
              <View style={styles.scaleRowCategories}>
                {categories && categories.map((category: any, index: number) => <Text key={index} style={styles.scaleRowCategoriesText}>{category.name}</Text>)}
              </View>
              <View style={styles.scaleRowScale}>
                {scale && scale.map((scaleValue: number) => {
                  const style = scaleValue === 0
                    ? [styles.scaleRowScaleNeutralValue]
                    : Number.isInteger(scaleValue)
                      ? [styles.scaleRowScaleFullValue]
                      : [styles.scaleRowScaleSubValue];
                  if (scaleValue === values[row]) style.push([styles.scaleRowValueIndicator,{borderColor: Colors.mainColorLight.color}]);
                  return <View key={scaleValue} style={style}
                  />;
                })
                }
              </View>
            </View>
          </View>
        );
      })}
    </View>
  </View>;
};

export default MeasurementScale;

const styles = StyleSheet.create({
  scaleContainer: {
    flex:1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: '4.5%',
    paddingBottom: '9.5%',
    paddingLeft: '8%',
    paddingRight: '8%',
    borderBottomColor: '#cacaca',
    borderBottomWidth: 2 / PixelRatio.get()
  },
  scaleCaption: {
    letterSpacing: 1.125,
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 18,
    marginBottom: '5%',
  },
  rowContainer: {
    height: '100%',
    width: '100%',
  },
  scaleRow: {
    height: '50%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: '12%'
  },
  scaleRowName: {
    maxHeight:'50%',
    width: '26%',
    marginBottom: '1.66%',
    marginRight: '4%',
  },
  scaleRowNameText: {
    letterSpacing: 1.125,
    fontFamily: 'Montserrat-Bold',
    fontSize: 12,
  },
  scaleRowScaleContainer: {
    width: '75%',
    height:'100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  scaleRowScale: {
    width: Platform.OS === 'web' ? '95%' : '100%',
    height: Platform.OS === 'web' ?'100%' : '75%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  scaleRowCategories: {
    width: '100%',
    height: Platform.OS === 'web' ?'100%' : undefined,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: Platform.OS === 'web' ?'4%' : '0%',
  },
  scaleRowCategoriesText: {
    letterSpacing: .42,
    color: '#19232c',
    fontFamily: 'Montserrat-Regular',
    fontSize: 8,
  },
  scaleRowValueIndicator: {
    borderRadius: 20,
    borderWidth: 5,
    padding: '.66%',
    margin: '-2.66%',
    height: Platform.OS === 'web' ?'150%' : '90%',
    width: '1.33%'
  },
  scaleRowScaleFullValue: {
    backgroundColor: '#000000',
    borderRadius: 4,
    height: Platform.OS === 'web' ?'90%' : '50%',
    width: '1.33%'
  },
  scaleRowScaleSubValue: {
    backgroundColor: '#000000',
    borderRadius: 3,
    height: Platform.OS === 'web' ?'50%' : '33%',
    width: '.66%'
  },
  scaleRowScaleNeutralValue: {
    backgroundColor: '#000000',
    borderRadius: 4,
    height: Platform.OS === 'web' ?'100%' : '66%',
    width: '1.66%'
  }
});
