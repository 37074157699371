/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ImageBackground, Image, StyleSheet, View, Text, TouchableOpacity, ActivityIndicator, Platform } from "react-native";
import { background, Icons } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import { Styles, Fonts } from "../../styles";
import SelectionCard from "../Scan/SelectionCard";

const RunningCatalog: React.FC = (): JSX.Element => {
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [language] = useGlobalState("language");
  const [Colors] = useGlobalState("colors");
  const [matchedProducts] = useGlobalState("matchedProducts");
  const [filterOption, setFilterOption] = useState<"APPLICATION" | "EXPERIENCE" | "UNDERGROUND">("EXPERIENCE");
  const [HeelValueFromScan, setHeelValueFromScan] = useState<any>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productsFound, setProductsFound] = useState<boolean>(true);
  const i18n = I18N(language);

  const [Title, setTitle] = useState<string>(i18n.t("RunningCtalog.runningExperience"));
  const [question, setQuestion] = useState<string>(i18n.t("RunningCtalog.runningExperienceQuestion"));

  const navigation = useNavigation();
  const heelCategories = ["pronation", "neutral", "supination"];

  useEffect(() => {
    if (currentMeasurements)
      setHeelValueFromScan(
        heelCategories[
          Math.min(currentMeasurements.measurements[0].categorized_measurements["heel_angle"].category, currentMeasurements.measurements[1].categorized_measurements["heel_angle"].category)
        ]
      );
  }, []);

  const EXPERIENCE_CHOICES = [
    { caption: "occasionally", value: "occasionally", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/experience-occasionally.png" },
    { caption: "regularly", value: "regularly", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/experience-regularly.png" },
    { caption: "ambitious", value: "ambitious", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/experience-ambitious.png" }
  ];

  const UNDERGROUND_CHOICES = [
    { caption: "trail", value: "trail", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/ground-trail.png" },
    { caption: "road", value: "road-or-treadmill", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/ground-road-or-treadmill.png" }
  ];
  
  const navigateBack = () => {
    if (filterOption === "EXPERIENCE") {
      navigation.goBack();
    }
    if (filterOption === "UNDERGROUND") {
      setFilterOption("EXPERIENCE"), setTitle(i18n.t("RunningCtalog.runningExperience")), setQuestion(i18n.t("RunningCtalog.runningExperienceQuestion"));
    }
    if (!productsFound) {
      setProductsFound(true);
    }
  };

  const selectExperience = () =>
    EXPERIENCE_CHOICES.map((choice: any, index: number) => (
      <SelectionCard
        key={index}
        caption={i18n.t(`RunningCtalog.${choice.caption}`)}
        image={choice.image}
        onPress={() => [
          setFilteredProducts(matchedProducts),
          setFilterOption("UNDERGROUND"),
          setTitle(i18n.t("RunningCtalog.terrain")),
          setQuestion(i18n.t("RunningCtalog.terrainQuestion"))
        ]}
      />
    ));

  const selectUnderground = () =>
    UNDERGROUND_CHOICES.map((choice: any, index: number) => (
      <SelectionCard
        key={index}
        caption={i18n.t(`RunningCtalog.${choice.caption}`)}
        image={choice.image}
        onPress={() => {
          let filtredProducts;
          if (choice.value === "trail") {
            filtredProducts = filteredProducts.filter((p: any) => p.products.underground === "trail");
          } else {
            filtredProducts = filteredProducts.filter((p: any) => p.products.underground === choice.value);
            if (HeelValueFromScan === "pronation") {
              filtredProducts = filtredProducts.filter((p: any) => p.products.support === "support");
            } else filtredProducts = filtredProducts.filter((p: any) => p.products.support === "neutral");
          }
          let filteredProductsToBeShown: any[] = [];
          filtredProducts.map((p: any) => {
            filteredProductsToBeShown.push({
              id: p.products.id,
              name: p.products.name,
              brand: p.products.brand,
              gtin: p.products.gtin,
              price: p.products.price,
              size: {
                eu: p.products.size.eu,
                uk: p.products.size.uk,
                us: p.products.size.us
              },
              ...(p.products.image_url && { image_url: p.products.image_url }),
              ...(p.products.thumb_url && { thumb_url: p.products.thumb_url }),
              description: p.products.description,
              last_width_category: p.products.last_width_category,
              width_name: p.products.width_name,
              msi: p.products.msi,
              leading_system: p.products.leading_system
            });
          });

          filteredProductsToBeShown.length > 0
            ? [
                navigation.push("CatalogProducts", { runningProducts: filteredProductsToBeShown }),
                setLoading(false)
              ]
            : [setLoading(false), setProductsFound(false)];
        }}
      />
    ));

  const reset = () => {
    setProductsFound(true);
    setFilterOption("EXPERIENCE");
    setTitle(i18n.t("RunningCtalog.runningExperience"));
    setQuestion(i18n.t("RunningCtalog.runningExperienceQuestion"));
  };
  const resetFilter = () => (
    <View style={styles.resetFilterWrapper}>
      <Image source={Icons.NotFoundImage} style={{ width: "50%", height: "50%", resizeMode: "contain", alignSelf: "center" }} />
      <View style={{ width: "40%", height: "50%", justifyContent: "space-evenly", alignItems: "center" }}>
        <Text style={styles.loadingText}>{i18n.t("RunningCtalog.noProducts")}</Text>
        <Text style={[Fonts.textSmaller, { textAlign: "center" }]}>{i18n.t("RunningCtalog.filterCriteriaMessage")}</Text>
        <TouchableOpacity style={Styles.submitButtonDark} onPress={() => reset()}>
          <Text style={[styles.buttonText, { color: "#ffffff" }]}>{i18n.t("RunningCtalog.resetFilter")}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} title={!isLoading && productsFound ? Title : i18n.t("RunningCtalog.title")}>
        <View style={styles.backButtonWrapper}>
          <TouchableOpacity style={[styles.backButton, { backgroundColor: Colors.mainColorDark.color }]} onPress={() => navigateBack()}>
            <Image source={Icons.backArrowIcon} style={styles.iconContainer} />
          </TouchableOpacity>
        </View>
        <View style={styles.contentWrapper}>
          {!isLoading && productsFound && <Text style={styles.questiontext}>{question}</Text>}
          <View style={styles.cardWrapper}>
            {isLoading ? (
              <View style={styles.loadingWrapper}>
                <Text style={styles.loadingText}>{i18n.t("loadProductMessage")}</Text>
                <ActivityIndicator size="large" color={"#2bc6ff"} />
              </View>
            ) : !productsFound ? (
              resetFilter()
            ) : (
              [
                filterOption === "EXPERIENCE" && selectExperience(),
                filterOption === "UNDERGROUND" && selectUnderground()
              ]
            )}
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default RunningCatalog;

const styles = StyleSheet.create({
  backgroundImage: {
    resizeMode: "cover",
    width: "100%",
    height: "100%",
    backgroundColor: "white"
  },
  contentWrapper: {
    flex: 1,
    marginTop: Platform.OS === "web" ? "0%" : "2%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  },
  cardWrapper: {
    flex: 1,
    width: "80%",
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "80%",
    marginTop: Platform.OS === "web" ? ".5%" : "3%"
  },
  selectionCaption: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    color: "#3a3a3a",
    marginTop: "3.5%"
  },
  backButtonWrapper: {
    height: Platform.OS === "web" ? "12%" : "1.3%",
    width: "5.2%",
    position: "absolute",
    zIndex: 2,
    marginTop: Platform.OS === "web" ? "1.2%" : "5.8%",
    top: 50,
    marginLeft: "1.6%"
  },
  backButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#8e8f90",
    opacity: 0.95,
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0
    }
  },
  iconContainer: {
    height: "50%",
    width: "50%",
    resizeMode: "contain"
  },
  loadingText: {
    fontFamily: "Montserrat-Black",
    fontSize: 19,
    fontWeight: "600",
    textAlign: "center",
    alignSelf: "center"
  },
  loadingWrapper: {
    width: "100%",
    height: "35%",
    justifyContent: "space-around",
    alignSelf: "center"
  },
  resetFilterWrapper: {
    width: "100%",
    height: "60%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10%",
    alignSelf: "flex-start"
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase"
  },
  questiontext: {
    fontFamily: "Montserrat-Regular",
    fontSize: 19,
    fontWeight: "600",
    textAlign: "center",
    alignSelf: "center",
    width: "75%"
  }
});
