export const background = require('./images/background.png');
export const defaultLogo = require('./images/OneFID_Logo_black.png');
export const greenDot = require('./images/greenDot.png');
export const redDot = require('./images/redDot.png');
export const scannerPlaceholder = require('./images/scanner-card-placeholder.png');

export const genderFemale = require('./images/gender/female.png');
export const genderMale = require('./images/gender/male.png');
export const modeDarkSocks = require('./images/mode/darkSocks.png');
export const modeBrightSocks = require('./images/mode/brightSocks.png');
export const modeBarefoot = require('./images/mode/barefoot.png');
export const leftFoot = require('./images/scan/leftFoot.png')
export const rightFoot = require('./images/scan/rightFoot.png')


export const footPainYes = require('./images/insole/footPainYes.png');
export const footPainNo = require('./images/insole/footPainNo.png');
export const usingInsolesYes = require('./images/insole/usingInsolesYes.png');
export const usingInsolesNo = require('./images/insole/usingInsolesNo.png');

 
const loginIconOutlined = require('./images/login.png');
const registerIcon = require('./images/register.png');
const loginIconFilled = require('./images/loginIcon.png');
const logoutIconEN = require('./images/logoutCircleEN.png');
const logoutIconDE = require('./images/logoutCircleDE.png');
const logoutIconSSV = require('./images/logoutCircleSSV.png');
const loginIconEN =  require('./images/loginCircleEN.png');
const loginIconDE =  require('./images/loginCircleDE.png');
const loginIconSSV =  require('./images/loginCircleSSV.png');
const loginIconTransparentEN =  require('./images/loginIconTransparentEN.png');
const loginIconTransparentDE =  require('./images/loginIconTransparentDE.png');
const userIcon = require('./images/loginIcon.png');
const closeIcon = require('./images/close.png');
const closeIconSSV = require('./images/closeSSV.png');
const supportIcon = require('./images/Iicon.png');
const errorIcon = require('./images/errorIcon.png');
const inboxIcon = require('./images/inbox.png');
const lockIcon = require('./images/lock.png');
const checkmarkIcon = require('./images/check.png');
const confirmEmailIcon = require('./images/confirmEmail.png');
const confirmEmailShoepassion = require('./images/confirmEmailShoepassion.png')
const mailIcon = require('./images/support/mailIcon.png');
const soleIcon = require('./images/sole-icon.png');
const backArrowIcon = require('./images/backArrow.png');
const saveContainerIcon = require('./images/star-icon.png');
const saveContainerIconActive = require('./images/star-icon-active.png');
const advertisingImage = require('./images/onefid-advertise.png');
const recommendationImage = require('./images/RECOMMENDED-FOR-YOU.png');
const feedbackIcon = require('./images/feedback.png');
const onefidIcon = require('./images/OneFIDIcon.png');
const onefidIconBlack = require('./images/OneFIDIconBlack.png');
const refreshIcon = require('./images/refreshIcon.png');
const askIcon = require('./images/askIcon.png');
const NotFoundImage = require('./images/NotFoundImage.png')
const checkmarkIconCircle = require('./images/checkcircle.png')
const closeIconSquare = require('./images/closeIconSquare.png')
const closeIconTransparent = require('./images/closeIconTransparent.png')
const closeIconSquareSSV = require('./images/closeIconSquareSSV.png')
const basketIcon = require('./images/basketIcon.png')
const customizeIcon = require('./images/customizeIcon.png')
const balanceIcon = require('./images/balance.png')
const activityIcon = require('./images/activity.png')
const barCodeIcon = require('./images/barcodeIcon.png')
const loadMoreIcon = require('./images/loadMoreIcon.png')
const searshLoop = require('./images/searshLoop.png')
const scrollEnd = require('./images/scrollEnd.png')
const scrollUp = require('./images/scrollUp.png')
const transparentUserIconEN = require('./images/userIconEN.png');
const transparentUserIconDE = require('./images/userIconDE.png');
const steitzIcon = require('./images/steitzIcon.png');
const hoffmanIcon = require('./images/hofmannLogo.png');
const eltenIcon = require('./images/ELTEN_Logo.png');
const corporate = require('./images/corporate.png');
const infoIconWhite = require('./images/infoIconWhite.png');
const infoIconBlack = require('./images/infoIconBlack.png');

const supportErrorIcon = require('./images/support/errorIcon.png');
const phoneIcon = require('./images/support/phoneIcon.png');
const mailIconBlack = require('./images/support/mailIconBlack.png');
const selectScannerIcon = require('./images/support/selectScannerIcon.png');
const calibrationIcon = require('./images/support/calibrationIcon.png');
export const Icons = {
  backArrowIcon,
  loginIconOutlined,
  registerIcon,
  loginIconFilled,
  logoutIconEN,
  logoutIconDE,
  logoutIconSSV,
  loginIconEN,
  loginIconDE,
  loginIconSSV,
  loginIconTransparentEN,
  loginIconTransparentDE,
  userIcon,
  closeIcon,
  closeIconSquare,
  closeIconTransparent,
  closeIconSquareSSV,
  closeIconSSV,
  supportIcon,
  confirmEmailIcon,
  confirmEmailShoepassion,
  errorIcon,
  inboxIcon,
  lockIcon,
  checkmarkIcon,
  checkmarkIconCircle,
  soleIcon,
  saveContainerIcon,
  saveContainerIconActive,
  advertisingImage,
  recommendationImage,
  feedbackIcon,
  onefidIcon,
  onefidIconBlack,
  refreshIcon,
  askIcon,
  NotFoundImage,
  basketIcon,
  customizeIcon,
  balanceIcon,
  activityIcon,
  barCodeIcon,
  loadMoreIcon,
  searshLoop,
  scrollEnd,
  scrollUp,
  transparentUserIconEN,
  transparentUserIconDE,
  steitzIcon,
  eltenIcon,
  hoffmanIcon,
  infoIconWhite,
  infoIconBlack,
  support: {
    selectScannerIcon,
    calibrationIcon,
    mailIcon,
    checkmarkIcon,
    errorIcon: supportErrorIcon,
    phoneIcon,
    mailIconBlack,
    corporate,
  }
};
 