import React, { useEffect, useState } from "react";
import { Image, ImageSourcePropType, StyleSheet, TouchableOpacity, Platform, View, Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useGlobalState } from "../../../shared/state";
import TextTitle from "../../Text/TextTitle";

interface Props {
  color: string;
  text?: string;
  icon?: ImageSourcePropType;
  navigateTo: string;
  navigateOptions?: any;
  deactivated?: boolean;
  onPress?: any;
}

const CircleButton = ({ color, text, icon, navigateOptions, navigateTo, deactivated = false, onPress }: Props) => {
  const [route, setRoute] = useGlobalState("currentScreen");
  const [, setModalIsDisplayed] = useGlobalState("modalIsDisplayed");
  const [measurements] = useGlobalState("currentMeasurements");
  const [Colors] = useGlobalState("colors");
  const [currentRoute, setCurrentRoute] = useState<string>("Home");

  const navigation = useNavigation();

  useEffect(() => {
    const routeIndex = navigation.getState().index;
    const routeName = navigation.getState().routeNames[routeIndex];
    setCurrentRoute(routeName);
  }, [navigation]);

  const navigate = (route: string | undefined, options: any = undefined) => {
    if (route === "Logout") {
      let redirectTo: string = Platform.OS === "web" ? route : measurements && currentRoute.includes("Measurement") ? "LogoutMeasurementModal" : "LogoutModal";
      setModalIsDisplayed(true);
      navigation.push(redirectTo, options);
      setRoute(redirectTo);
    } else if (route === "NewScanGenderSelection" && measurements !== undefined) {
      let redirectTo: string = Platform.OS === "web" ? "NewScan" : currentRoute.includes("Measurement") ? "NewScanMeasurementModal" : "NewScanModal";
      setModalIsDisplayed(true);
      navigation.push(redirectTo, options);
      setRoute(redirectTo);
    } else if (route && route.includes("Modal")) {
      setModalIsDisplayed(true);
      navigation.push(route, options);
      setRoute(route);
    } else if (route === "DetectedScanners") {
      navigation.replace(route, options);
      setRoute(route);
    } else if (route === "CloseButton") {
      navigation.goBack();
    } else if (route === "Measurements" && Platform.OS === "web") {
      navigation.push(route, options);
      setRoute(route);
    } else if (route) {
      navigation.navigate(route, options);
      setRoute(route);
    }
  };

  const windowWidth = Dimensions.get("window").width;
  const circleIconContainerWidth = windowWidth < 2000 ? "95%" : "95%";
  const circleIconContainerHeight = windowWidth < 2000 ? "95%" : "95%";

  const btnColor: string = deactivated ? "white" : currentRoute.includes(navigateTo) ? Colors.mainColorDarkHover.color : color;

  const toProps = {
    // @ts-ignore
    style: [styles.circleButton, { backgroundColor: "transparent" }],
    onPress: deactivated ? undefined : onPress ? () => onPress() : () => navigate(navigateTo, navigateOptions),
    disabled: [currentRoute != navigateTo || route != navigate, currentRoute === "DetectedScanners"] ? false : true,
  };

  const textTitleProps = {
    color: btnColor === "white" ? "black" : "white",
    fontWeight: "900",
    fontSize: 21,
  };

  return (
    <TouchableOpacity {...toProps}>
      <View style={[styles.container, {backgroundColor: color}]}>
      {text && <TextTitle text={text} {...textTitleProps} />}
      {icon && <Image source={icon} style={styles.circleIconContainer} />}
      </View>
    </TouchableOpacity>
  );
};

export default CircleButton;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height:"100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
    maxHeight: 98,
    maxWidth: 98
  },
  circleButton: {
    height: "100%",
    width: "100%",
    aspectRatio: 1,
  },
  circleIconContainer: {
    alignContent: "center",
    resizeMode: "contain",
    width: "95%",
    height: "95%",
  },
});
