import React, { useState, useEffect } from 'react';
import { ImageBackground, StyleSheet, ActivityIndicator, Image, Text, TouchableOpacity, View } from 'react-native';
import { background, Icons } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { groupByKey, I18N } from '../../shared/util';
import { Styles, Fonts } from '../../styles';
import CategoryCard from './CategoryCard';
import { CategoryCardDTO } from '../../shared/model/types';


const PreFilterCatalog: React.FC = (): JSX.Element => {
  const [language] = useGlobalState("language");
  const [matchedProducts] = useGlobalState("matchedProducts");
  const [currentScanner] = useGlobalState('currentScanner');
  const [categoryCards, setCategoryCards] = useState<any>([]);
  const [Colors] = useGlobalState('colors');

  const i18n = I18N(language);
  const navigation = useNavigation();

  const nav = (category: CategoryCardDTO) => {
    category.attributes ? navigation.push("CatalogTree", { category: category })
      : navigation.navigate('CatalogProducts', { categoryID: category.id });

  };

  useEffect(() => {
    if (matchedProducts && categoryCards.length === 0) {
      currentScanner.catalog.categories.forEach((category: CategoryCardDTO, index: number) => {
        for (let categoryID of Object.keys(groupByKey(matchedProducts, "id"))) {
          if (categoryID === category.id) {
            setCategoryCards((prevState: any) => [...prevState, <CategoryCard key={index} CategoryCard={category} onPress={nav} />]);
            break;
          }
        }
      })
    }
    return () => setCategoryCards([])
  }, [matchedProducts]);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={i18n.t("catalog")}>
        <View style={styles.contentWrapper}>
          {matchedProducts && Object.keys(matchedProducts).length == 0
            ? <View style={styles.noProductsWrapper}>
              <Image source={Icons.NotFoundImage} style={{ width: '50%', height: '50%', resizeMode: 'contain', alignSelf: 'center' }}></Image>
              <Text style={styles.loadingText}>{i18n.t('noProductFoundMessage')}</Text>
              <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.navigate('NewScanGenderSelection', { resetState: true })}>
                <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t('new scan')}</Text>
              </TouchableOpacity>
            </View>
            :
            categoryCards.length > 0
              ? <View style={styles.cardWrapper}>{categoryCards}</View>
              : <View style={styles.loadingWrapper}>
                <Text style={styles.loadingText}>{i18n.t('loadProductMessage')}</Text>
                <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
              </View>}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default PreFilterCatalog;

const styles = StyleSheet.create({
  contentWrapper:{
    flex: 1,
    marginTop: '2.2%',
    alignItems:'center',
  },
  cardWrapper: {
    width: '81%',
    height: '90%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '0.4%',
    paddingLeft: '0.2%'
  },
  titeText: {
    ...Fonts.textHuge,
    ...Fonts.center,
    textTransform: 'uppercase'
  },
  loadingText: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 19,
    fontWeight: '600',
    textAlign: 'center',
    alignSelf: 'center'
  },
  noProductsWrapper: {
    width: '100%',
    height: '65%',
    justifyContent: 'space-around',
    alignSelf: 'center',
  },
  loadingWrapper: {
    width: '100%',
    height: '35%',
    justifyContent: 'space-around',
    alignSelf: 'center',
  },
  buttonText: {
    ...Fonts.mRegular,
    ...Fonts.size12,
    ...Fonts.center,
    textTransform: 'uppercase'
  },
});
