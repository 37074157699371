import React, { useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, View, Image, TouchableOpacity, Platform } from 'react-native';
import { background, greenDot, redDot, Icons } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { Styles } from '../../styles';
import TextTitle from '../../components/Text/TextTitle';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { useNavigation } from '@react-navigation/native';

const NewScan: React.FC<any> = (): JSX.Element => {
  const [, setCurrentScreen] = useGlobalState('currentScreen');
  const [currentUser, setCurrentUser] = useGlobalState('currentUser');
  const [, setCurrentMeasurements] = useGlobalState('currentMeasurements');
  const [, setMatchedProducts] = useGlobalState('matchedProducts');
  const [, setAllProducts] = useGlobalState('allProducts');
  const [, setCurrentScan] = useGlobalState('currentScan');
  const [userToken, setUserToken] = useGlobalState('userToken');
  const [currentScanner] = useGlobalState("currentScanner");
  const [language] = useGlobalState('language');
  const [Colors] = useGlobalState('colors');

  const [userInfo, setUserInfo] = useState<string | undefined>(undefined);

  const i18n = I18N(language);
  const navigation = useNavigation();

  const logout = () => {
    setCurrentUser(undefined);
    setUserToken(undefined);
    setCurrentMeasurements(undefined);
    setMatchedProducts(undefined);
    setCurrentScan(undefined);
    setAllProducts([]);
    setCurrentScreen('Home');
    navigation.navigate('Home');
  };


  useEffect(() => {
    setUserInfo(userToken
      ? currentUser.firstName.length > 0 && currentUser.lastName.length > 0
        ? `${currentUser.firstName} ${currentUser.lastName}`
        : currentUser.email
      : currentUser && currentUser.employeeNumber
        ? currentUser.employeeNumber
        : i18n.t('new user'))
      if (currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv" && currentUser) setUserInfo(`${currentUser.firstName} ${currentUser.lastName}`);

  }, [])

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton showHeaderLogo showBackButton={false}>
        <View style={styles.container}>
          <View style={styles.content}>
            <View style={styles.iconsContainer}>
              <Image style={styles.DotWrapper} source={currentUser ? greenDot : redDot} />
              <Image style={styles.iconWrapper} source={Icons.loginIconOutlined} />
            </View>
            <TextTitle text={userInfo} fontFamily="Montserrat-Regular" fontSize={15} fontWeight="normal" uppercase={false} />
            <View style={styles.divider} />
            <TextTitle
              text={currentUser ? i18n.t('new scan modal logged in user') : i18n.t('new scan modal not logged in user')}
              fontFamily="Montserrat-Regular"
              fontSize={14}
              fontWeight="normal"
              uppercase={false}
            />
            <TouchableOpacity style={Styles.submitButtonLight} onPress={() => (currentUser ? logout() : Platform.OS === 'web' ? navigation.replace('Register') : navigation.replace('RegisterModal'))}>
              <TextTitle fontSize={12} fontWeight="normal" text={currentUser ? i18n.t('auth.logOut') : i18n.t('save result')} />
            </TouchableOpacity>
            <TouchableOpacity style={[Styles.submitButtonDark, {backgroundColor: Colors.mainColorDark.color}]} onPress={() => navigation.navigate('NewScanGenderSelection', { resetState: true })}>
              <TextTitle fontSize={12} fontWeight="normal" text={i18n.t('new scan')} color="white" />
            </TouchableOpacity>
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default NewScan;

const styles = StyleSheet.create({
  container: {
    width: '70%',
    height: '90%',
    alignSelf: 'center',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    width: '50%',
    height: '80%',
    justifyContent: 'space-evenly',
    alignSelf: 'center',
  },
  iconsContainer: {
    width: '40%',
    height: '25%',
    alignSelf: 'center',
  },
  DotWrapper: {
    alignSelf: 'flex-end',
    width: '12%',
    height: '12%',
    marginRight: '10%',
    resizeMode: 'contain',
  },
  iconWrapper: {
    width: '75%',
    height: '70%',
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  divider: {
    width: '60%',
    alignSelf: 'center',
    borderTopColor: '#707070',
    borderTopWidth: 1,
  },
});
