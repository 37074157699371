import React, { useEffect, useState } from 'react';
import { ImageBackground, Image, StyleSheet, TouchableOpacity, View, Platform } from 'react-native';
import { Icons, background, footPainNo, footPainYes, usingInsolesNo, usingInsolesYes } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import I18n from '../../i18nSets/i18n';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { Styles } from '../../styles';
import SelectionCard from '../Scan/SelectionCard';
import QuestionnaireEvaluation from './QuestionnaireEvaluation';

const images = {
  q1yes: footPainYes,
  q1no: footPainNo,
  q2yes: usingInsolesYes,
  q2no: usingInsolesNo
};

export const insoleCategories = ["none", "low", "mid", "high", "special"];

const InsoleRecommendation: React.FC<any> = ({route}): JSX.Element => {
  const { params } = route;
  const nav = useNavigation();
  const [recommendationStep, setStep] = useState(1);
  const [conclusion, setConclusion] = useState<number>();
  const [insole, setInsole] = useState<string>("")
  const [language] = useGlobalState('language');
  const [insoleQuestionnaire, setInsoleQuestionnaire] = useGlobalState('insoleQuestionnaire');
  const [currentMeasurements] = useGlobalState('currentMeasurements');
  const [Colors] = useGlobalState("colors");


  useEffect(() => {
    if (params && params.step) setStep(params.step || 1);
  });

  useEffect(() => {
    if (currentMeasurements) {
      var insoleCategoryIndex = Math.min(insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation), insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation));
      setInsole(insoleCategories[insoleCategoryIndex])
    }
  }, [currentMeasurements])

  useEffect(() => {
    if (recommendationStep === 3) {
      recommendInsole();
    }
  }, [recommendationStep]);

  const recommendInsole = () => {
    if (!insoleQuestionnaire.question1 && !insoleQuestionnaire.question2) {
      switch (insole) {
        case 'none':
          setConclusion(0);
          break;
        case 'special':
          setConclusion(1);
          break;
        default:
          setConclusion(2);
      }
    } else {
      setConclusion(1);
    }
  };

  const select = (answer: boolean) => {
    if (recommendationStep < 3) {
      const question = `question${recommendationStep}`;
      const questionnaire = insoleQuestionnaire;
      questionnaire[question] = answer;

      setInsoleQuestionnaire(questionnaire);
      nav.navigate('InsoleRecommendation', { step: recommendationStep + 1 });
    }
  };

  const navigateBack = () => {
    if (recommendationStep > 1) {
      const previousStep = recommendationStep - 1;
      setStep(previousStep);
      nav.navigate('InsoleRecommendation', { step: previousStep });
    } else {
      nav.goBack(); // If on the first step, go back to the previous screen
    }
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
      <LayoutWrapper showHeaderCloseButton={false} showBackButton={false} showHeaderLogo subtitle={conclusion !== 2 ? I18n.t(`insole.q${recommendationStep}caption`, { locale: language }) : ' '}>
        <View style={styles.backButtonWrapper}>
          <TouchableOpacity style={[styles.backButton, { backgroundColor: Colors.mainColorDark.color }]} onPress={() => navigateBack()}>
            <Image source={Icons.backArrowIcon} style={styles.iconContainer} />
          </TouchableOpacity>
        </View>
        <View style={styles.contentWrapper}>
          {recommendationStep < 3
            ? <>
              <View style={styles.cardWrapper}>
                <SelectionCard value={I18n.t('yes', { locale: language }).toUpperCase()}
                  image={images[`q${recommendationStep}yes`]}
                  onPress={() => select(true)} />
                <SelectionCard value={I18n.t('no', { locale: language }).toUpperCase()}
                  image={images[`q${recommendationStep}no`]}
                  onPress={() => select(false)} />
              </View>
            </>
            : conclusion !== undefined
              ? <QuestionnaireEvaluation conclusion={conclusion} onClick={() => nav.push('Catalog')} />
              : undefined
          }
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default InsoleRecommendation;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  cardWrapper: {
    width:'75%',
    height:'100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '70%',
    marginTop: '3%',
  },
  selectionCaption: {
    width: '75%'
  },
  backButtonWrapper: {
    height: Platform.OS === "web" ? "12%" : "1.3%",
    width: "5.2%",
    position: "absolute",
    zIndex: 2,
    marginTop: Platform.OS === "web" ? "1.2%" : "5.8%",
    top: 50,
    marginLeft: "1.6%"
  },
  backButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#8e8f90",
    opacity: 0.95,
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0
    }
  },
  iconContainer: {
    height: "50%",
    width: "50%",
    resizeMode: "contain"
  },
});
