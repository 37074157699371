import React, { useState, useEffect } from 'react';
import { Image, View, StyleSheet, Text, ActivityIndicator, TouchableOpacity, PixelRatio, ImageBackground, Platform } from 'react-native';
import I18n from '../../i18nSets/i18n';
import { useGlobalState } from '../../shared/state';
import { Fonts } from '../../styles/font.styles';
import { background, Icons } from '../../assets';
import { Styles } from '../../styles';
import SizeScale from '../../components/SizeScale/SizeScale';
import * as WebBrowser from 'expo-web-browser';
import { useNavigation } from '@react-navigation/native';
import MarketingProductExtension from '../Product/MarketingProductExtension';
import { insoleCategories } from '../InsoleRecommendation/InsoleRecommendation';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import CrossProductExtension from '../Product/CrossProductExtension';

const ProductDetail: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  const [language] = useGlobalState('language');
  const [currentMeasurements] = useGlobalState('currentMeasurements');
  const [currentScanner] = useGlobalState('currentScanner');
  const [allProducts] = useGlobalState('allProducts');
  const [Colors] = useGlobalState('colors');

  const navigation = useNavigation();

  const [product] = useState<any>(params.product || undefined)
  const [warehouseBins, setWarehouseBins] = useState<string[]>([])
  const [isLoading, setLoading] = useState<boolean>(true);

  const primarySize: any = product.leading_system === 'eu' ? product.size.eu : product.leading_system === 'uk' ? product.size.uk : product.size.us
  const secondarySize: any = currentScanner.enableSecondarySize || !('enableSecondarySize' in currentScanner)
    ? product.leading_system === 'eu'
      ? product.size.secondary_eu
      : product.leading_system === 'uk'
        ? product.size.secondary_uk
        : product.size.secondary_us
    : undefined
  const price: any = product.price || undefined
  const salePrice: any = product.salePrice || undefined

  const EnableOrderButton: boolean = currentScanner.brandsToOrder && currentScanner.brandsToOrder.length > 0 ? currentScanner.brandsToOrder.toLocaleString().toLowerCase().split(',').includes(params.product.brand.toLowerCase()) ? true : false : true

  const insoleCategoryIndex = currentMeasurements ? Math.min(insoleCategories.indexOf(currentMeasurements.measurements[0].insole_recommendation), insoleCategories.indexOf(currentMeasurements.measurements[1].insole_recommendation)) : 0

  // if categoryToRecommend list is empty or undefined we display isoles for all product-category 
  const isRecommendedForCategory = (): boolean => !currentScanner.insoleRecommendation.categoryToRecommend
    ? true
    : currentScanner.insoleRecommendation.categoryToRecommend.includes(product.categoryName?.toLowerCase()) || !currentScanner.insoleRecommendation.categoryToRecommend.length ?
      true
      : false

  const displayInsoleRecommendation: boolean =
    insoleCategories[insoleCategoryIndex] !== 'none' &&
    insoleCategories[insoleCategoryIndex] !== 'special' &&
    currentScanner.insoleRecommendation &&
    isRecommendedForCategory()

  useEffect(() => {
    if (product) {
      setLoading(false)
      if (currentScanner.displayStock)
        getProductWhereHouseBin()
    }
  }, []);

  const getProductWhereHouseBin = () => {
    var warehouseBinsArray: any[] = []
    allProducts.map(products => {
      products.details.map((detail: any) => {
        if (product.gtin === detail.gtin && currentScanner['storeid'] === detail.store_id)
          detail.warehouse_bin ? warehouseBinsArray.push(detail.warehouse_bin) : undefined
      })
    })
    setWarehouseBins(warehouseBinsArray)
  }

  const orderProduct = () => {
    navigation.navigate('Order', { GTIN: product?.gtin });
  }

  const customiseProduct = () => {
    navigation.navigate('CustomiseProducOrder', { GTIN: product?.gtin });
  }
  const openShop = (gtin?: string, size?: string) => WebBrowser.openBrowserAsync(`https://desma.expivi.com/product/perfectknit3d/?gtin=${gtin}&size=${size}`, {windowFeatures: {width:1200,height:1000}}).catch((err) => console.error('An error occurred', err));

  /**
   * @description function to render the extension card
   */
  const renderExtensionCard = () => {
    return (
      <View style={styles.extensionCard} >
        <View style={styles.advertisingProductCard}>
          {/* <Image source={Icons.advertisingImage} style={styles.imageContainer} /> */}
          {secondarySize ? <>
            <Text style={Fonts.textHuge}>{I18n.t('secondaryRecommendatiom.yourSize')}</Text>
            <Text style={styles.secondaryRecomendationText}>{I18n.t('secondaryRecommendatiom.message1')}</Text>
            <Text style={styles.secondaryRecomendationText}>{I18n.t('secondaryRecommendatiom.message2')} {secondarySize < primarySize ? secondarySize : primarySize} </Text>
            <Text style={styles.secondaryRecomendationText}>{I18n.t('secondaryRecommendatiom.message3')} {secondarySize > primarySize ? secondarySize : primarySize}</Text>
          </>
            : <Text style={Fonts.textGigantic}>{I18n.t('advertisingOnefid')}</Text>}
            {/* : <Image source={Icons.advertisingImage} style={styles.imageContainer} /> */}
        </View>
        {(currentScanner.enableExpiviShop && product.brand === "DESMA") ? <TouchableOpacity style={styles.basketContainer} onPress={() => openShop(product?.gtin, primarySize)} >
          <View style={styles.basketContainerView}>
            <Image source={Icons.basketIcon} style={styles.basketIcon}></Image>
            <Text style={styles.basketContainerText}>{I18n.t('expiviConfigurator')}</Text>
          </View>
        </TouchableOpacity>
          : currentScanner.enableOrder && EnableOrderButton ?
            <TouchableOpacity style={styles.basketContainer} onPress={() => product?.msi ? customiseProduct() : orderProduct()} >
              <View style={styles.basketContainerView}>
                <Image source={Icons.basketIcon} style={styles.basketIcon}></Image>
                <Text style={styles.basketContainerText}>{I18n.t('order now', { locale: language })}</Text>
              </View>
            </TouchableOpacity> : undefined}
      </View>
    );
  }

  if (isLoading) {
    return (
      <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
        <LayoutWrapper showHeaderCloseButton={false} showBackButton={true} showHeaderLogo={true}  >
          <View style={styles.loader}>
            <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
          </View>
        </LayoutWrapper>
      </ImageBackground>
    )
  }
  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={true} showHeaderLogo={true} showBackButton={false} >
        <View style={styles.wrapper}>

          <View style={styles.productContainer}>
            {product &&
              <View style={styles.card} >
                <View style={styles.productImageWrapper}>
                  <View style={styles.imageGallery}>
                    <Image source={{ uri: product.image_url }} style={styles.productImageContainer} />
                  </View>
                </View>
                <View style={styles.productDetailsWrapper}>

                  <View style={styles.productCardDetails}>
                    <Text style={styles.productDescriptionText}>{product.description}</Text>
                    <Text adjustsFontSizeToFit style={styles.productCardNameText}>{product.name}</Text>
                    {product.width_name != undefined && <Text style={styles.widthNameText}>{product.width_name}</Text>}
                    {product.price != undefined &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.productCardPriceText}>{`${I18n.t('price')}: `}</Text>
                        {salePrice && price && salePrice < price ?
                          <>
                            <Text style={[styles.productCardPriceText, { textDecorationLine: 'line-through' }]}>{`${price}€ `}</Text>
                            <Text style={[styles.productCardPriceText, { color: 'red' }]}>{`${salePrice}€`}</Text>
                          </>
                          : <Text style={styles.productCardPriceText}>{`${price}€`}</Text>
                        }
                      </View>}
                    <Text style={styles.productCardManufacturerText}>{product.brand} - {product.gtin}</Text>
                    {warehouseBins.length > 0 && <Text style={styles.productCardPriceText}>{warehouseBins.map((w => `${w}  `))}</Text>}
                  </View>
                  <View style={styles.sizeWrapper}>

                    <View style={styles.soleIconContainer}>
                      <Image source={Icons.soleIcon} style={styles.soleIcon}></Image>
                      <Text style={styles.soleIconText}>{product.last_width_category}</Text>
                    </View>
                    <View style={[styles.sizeContainer, { backgroundColor: Colors.mainColorDark.color }]}>
                      <SizeScale caption={"EU"}
                        valueCount={5}
                        categories={secondarySize ?
                          secondarySize < primarySize ? [{ name: secondarySize, value: secondarySize }, { name: primarySize, value: primarySize }]
                            : [{ name: primarySize, value: primarySize }, { name: secondarySize, value: secondarySize }]
                          : [{ name: '', value: 0 }, { name: primarySize, value: 2 }, { name: '', value: 4 }]}
                        values={{ left: 2 }}
                      />
                    </View>
                  </View>
                </View>
              </View>}
          </View>
          <View style={styles.subContainer}>
            {product.crossProduct ?
              <CrossProductExtension crossProduct={product.crossProduct} />
              : displayInsoleRecommendation
                ? <MarketingProductExtension enableOrderButton productData={product}/>
                : renderExtensionCard()}
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>);
};

export default ProductDetail;

const styles = StyleSheet.create({
  wrapper: {
    height: '90%',
    width: '70%',
    flexDirection: 'row',
    alignSelf: 'center',
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: .16,
    elevation: 1,
    opacity: 1
  },
  productContainer: {
    height: '100%',
    width: '65%',
    shadowColor: '#000',
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: .16,
    elevation: 1,
    opacity: 1
  },
  subContainer: {
    height: '100%',
    width: '35%',
    justifyContent: 'flex-end'
  },
  card: {
    height: '98.5%',
    justifyContent: 'space-between',
    backgroundColor: 'white'
  },
  extensionCard: {
    height: '98.5%',
    justifyContent: 'space-between',
  },
  productImageWrapper: {
    height: '65%',
  },
  sizeWrapper: {
    height: '100%',
    width: '30%',
    flexDirection: 'row',
  },
  soleIconContainer: {
    width: '50%',
    height: '60%',
    justifyContent: "center",
    alignItems: "center",
    alignSelf: 'flex-end',
  },
  soleIcon: {
    width: '100%',
    height: '80%',
    resizeMode: 'contain',
    zIndex: 10
  },
  soleIconText: {
    ...Fonts.mSemiBold,
    fontSize: 16,
    fontWeight: '900',
    position: 'absolute',
    right: '45%',
    bottom: '40%',
    zIndex: 11
  },
  imageContainer: {
    width: '90%',
    height: '90%',
    resizeMode: 'contain'
  },
  productImageContainer: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain'
  },
  productDetailsWrapper: {
    height: '35%',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: 9

  },
  productCardDetails: {
    height: '100%',
    width: '70%',
    alignSelf: 'flex-end',
    padding: '2%'
  },
  productCardNameText: {
    textAlign: "left",
    fontFamily: 'Montserrat-Black',
    fontSize: 16,
    marginTop: 10
  },
  productCardPriceText: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 14
  },
  productCardManufacturerText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 12
  },
  sizeContainer: {
    alignSelf: 'flex-end',
    height: '55%',
    width: '50%'
  },
  loader: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '2%',
    paddingLeft: '1%'
  },
  productDescriptionText: {
    fontFamily: 'Montserrat-Light',
    fontSize: 13,
    paddingBottom: '5%',
    overflow: 'scroll'
  },
  basketContainer: {
    width: '98.9%',
    height: '20%',
    alignSelf: 'flex-end',
    borderWidth: 1,
    borderColor: '#d6d7da',
    shadowColor: "#000",
    shadowOffset: {
      width: 0.2,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 1,
    elevation: 1,
    backgroundColor: 'white',
    marginTop: '2%'
  },
  basketContainerView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    alignContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  basketIcon: {
    width: '15%',
    height: '40%',
    resizeMode: 'contain',
    marginRight: '5%'
  },
  basketContainerText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 15
  },
  advertisingProductCard: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'center',
    height: '70%',
    width: '90%',
  },
  imageGallery: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  secondaryRecomendationText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 15,
    textAlign: 'center'
  },
  widthNameText: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 14,
    fontWeight: '600',
    right: "-36%",
    bottom: 140,
    position: 'absolute'
  },
});
