import React, { useEffect, useState } from "react";
import { ActivityIndicator, ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import { background } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useNavigation } from '@react-navigation/native';
import API from "../../shared/api";
import { ScanDTO } from "../../shared/model/types";
import { useGlobalState } from "../../shared/state";
import { Fonts, Styles } from "../../styles";
import ScanFailedModal from "./ScanFailedModal";
import ScannerCoreService from "../../shared/services/core-services";
import { I18N, sleep } from "../../shared/util";
import { getMatchedproducts } from "../../shared/products";
import * as Sentry from "sentry-expo";
import { Gender } from "../../shared/model/enums";

const ScanSuccessfulModal: React.FC = (): JSX.Element => {
  const [currentScan, setCurrentScan] = useGlobalState("currentScan");
  const [, setMatchedProducts] = useGlobalState("matchedProducts");
  const [, setRoute] = useGlobalState("currentScreen");
  const [, setCurrentMeasurements] = useGlobalState("currentMeasurements");
  const [, setAllProducts] = useGlobalState("allProducts");
  const [Colors] = useGlobalState('colors');
  const [customerStoreID] = useGlobalState("customerStoreID");
  const [currentUser] = useGlobalState("currentUser");
  const [newScanState] = useGlobalState("newScanState");
  const [scanningDATA] = useGlobalState("scanningDATA");
  const [language] = useGlobalState("language");
  const [currentScanner] = useGlobalState("currentScanner");
  const [userToken] = useGlobalState("userToken");
  const [userOrigin] = useGlobalState("userOrigin");
  const [userBirthDate] = useGlobalState("userBirthDate");
  const [errorCode, setErrorCode] = useState<string>("");

  const [scanProcessResult, setScanProcessResult] = useState<"SUCCESS" | "FAILURE">();
  const sleepTime: number = 700;
  var counter: number = 0;

  const navigation = useNavigation();
  const i18n = I18N(language);

  const navigate = (route: string | undefined) => {
    if (route) {
      navigation.navigate(route);
      setRoute(route);
    }
  };

  const reset = () => {
    navigation.navigate("NewScanGenderSelection", { resetState: true });
  };

  const getScan = (measurements_url: string) => {
    API.get(measurements_url.replace("measurements/", ""))
      .then((scanResult: any) => {
        if (counter < 100) {
          if (scanResult.scanfiles[0].status !== "Processed" || scanResult.scanfiles[1].status !== "Processed") {
            sleep(sleepTime).then(() => {
              counter++;
              getScan(measurements_url);
            });
          } else {
            if (currentScanner.components.find((component: any) => component.caption.toLowerCase().includes("filter"))) {
              var storeID: string = customerStoreID ? customerStoreID : currentScanner["storeid"] ;
              var gender: string = newScanState.gender === Gender.FEMALE ? "f" : "m";
              var customer_uuid = scanResult.customer_uuid;
              var lastwidthcategoryMode: string = currentScanner.lastwidthcategoryMode || "default";
              var safetyClass: string = (currentUser && currentUser.safetyClass) || undefined;
              var showPartnerProducts: boolean = currentScanner["showPartnerProducts"] || undefined;
              getMatchedproducts(setAllProducts, setMatchedProducts, lastwidthcategoryMode, customer_uuid, currentScanner["attribute_name"], gender, safetyClass, storeID, showPartnerProducts);
            }
            if (currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv")
              API.put(
                "https://ssvkroschkepov-dev.outsystemsenterprise.com/SHOEPoint_BE/rest/SP_RESTAPI/AddShoePassID",
                {
                  ExternalID: currentUser.externeID,
                  ShoePass:  currentUser.employeeNumber,
                  K_Debitor: currentScanner.kDebitor
                },
                {
                  auth: {
                    username: "SHOEPoint",
                    password: "hCt73mGCDgUQ733"
                  }
                }
              );
            setCurrentScan(scanResult);
            setCurrentMeasurements({
              measurements: [
                {
                  condition: scanResult.scanfiles.find((result: any) => result.side === "left").condition,
                  side: "Left",
                  measurements: scanResult.scanfiles.find((result: any) => result.side === "left").measurements,
                  categorized_measurements: scanResult.scanfiles.find((result: any) => result.side === "left").categorized_measurements,
                  insole_recommendation: scanResult.scanfiles.find((result: any) => result.side === "left").insole_recommendation,
                  colored_sole: scanResult.scanfiles.find((result: any) => result.side === "left").colored_sole
                },
                {
                  condition: scanResult.scanfiles.find((result: any) => result.side === "right").condition,
                  side: "Right",
                  measurements: scanResult.scanfiles.find((result: any) => result.side === "right").measurements,
                  categorized_measurements: scanResult.scanfiles.find((result: any) => result.side === "right").categorized_measurements,
                  insole_recommendation: scanResult.scanfiles.find((result: any) => result.side === "right").insole_recommendation,
                  colored_sole: scanResult.scanfiles.find((result: any) => result.side === "right").colored_sole
                }
              ]
            });
            setScanProcessResult("SUCCESS");
            setRoute("Measurements");
            navigation.push("Measurements");
          }
        } else {
          setScanProcessResult("FAILURE");
          console.log("ERROR getScan: time exceeded to get processed scan", counter);
          if(Platform.OS !== 'web') Sentry.Native.captureException(`ERROR getScan: time exceeded to get processed scan: ${counter}`);
        }
      })
      .catch(error => {
        setScanProcessResult("FAILURE");
        console.log("ERROR getScan: ", error);
      });
  };

  useEffect(() => {
    if (scanningDATA && !currentScan) {
      // upload scan to core
      ScannerCoreService.postScan(scanningDATA, currentScanner["scanner-token"])
        .then((response: ScanDTO) => {
          getScan(response.measurements_url);
          // setContient to user profile
          if (userToken && userOrigin)
            ScannerCoreService.updateUserOrigin(userToken, userOrigin).catch(error => {
              console.log("ERROR updateUserOrigin: ", error);
            });

          if (userToken && userBirthDate)
            ScannerCoreService.updateUserBirthDate(userToken, userBirthDate).catch(error => {
              console.log("ERROR updateUserBirthDate: ", error);
            });
        })
        .catch(error => {
          console.log("error", error);
          if (error.response_code === 404) {
            setErrorCode('scannerBusy')
          } else {
            if (Array.isArray(error.message.errors)) {
              setErrorCode(error.message.errors[0].error_code)
            } else if (error.message && error.message.includes('foam')) {
              setErrorCode('foamBoxNotImplemented')
            } else {
              setErrorCode('genericError')
            }
          }
          setScanProcessResult("FAILURE");
          console.log("ERROR postScan: ", error);
        });
    }
  }, []);

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} showMenuButtons={false} hideNewScanButton hideLeftButton>
        <View style={styles.contentWrapper}>
          {scanProcessResult && scanProcessResult === "FAILURE" ? (
            <>
              <ScanFailedModal errorCode={errorCode} onScanAgain={() => reset()} onCancel={() => navigate("Home")} />
            </>
          ) : (
            <>
              <View style={styles.contentBox}>
                <View style={styles.captionContainer}>
                  <Text style={styles.caption}>{i18n.t("scan.succesScanMessage")[0]}</Text>
                </View>
                <View style={styles.mainMessageContainer}>
                  <Text style={styles.mainMessage}>
                    {i18n.t("scan.succesScanMessage")[1]}
                    <Text style={Fonts.mBlack}>{i18n.t("scan.succesScanMessage")[2]}</Text> {i18n.t("scan.succesScanMessage")[3]}
                  </Text>
                </View>
                <View style={styles.subMessageContainer}>
                  <Text style={styles.subMessage}>{i18n.t("scan.succesScanMessage")[4]}</Text>
                </View>
                <View style={styles.buttonRowContainer}>
                  <View style={styles.processingWrapper}>
                    <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
                  </View>
                </View>
              </View>
            </>
          )}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default ScanSuccessfulModal;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  contentBox: {
    backgroundColor: "#ffffff",
    opacity: 0.9,
    width: "80%",
    minHeight: "90%",
    maxHeight: "90%",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "1.5%",
    marginTop: "5%",
    elevation: 1
  },
  captionContainer: {
    height: "20%",
    marginTop: "10%"
  },
  caption: {
    fontFamily: "Montserrat-Black",
    fontSize: 24,
    color: "#000000"
  },
  mainMessageContainer: {
    height: "20%",
    width: "53%",
    maxWidth: "60%"
  },
  mainMessage: {
    fontFamily: "Montserrat-Regular",
    fontSize: 40,
    textAlign: "center",
    color: "#000000"
  },
  subMessageContainer: {
    height: "20%"
  },
  subMessage: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#000000"
  },
  buttonRowContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "80%"
  },
  processingWrapper: {
    minHeight: "90%",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
});
