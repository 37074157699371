import I18n from '../../i18nSets/i18n';
import * as _ from 'lodash';


export const chunk = (array: any[], size: number) => array.reduce((chunks, el, i) => (i % size
  ? chunks[ chunks.length - 1 ].push(el)
  : chunks.push([ el ])) && chunks, []);

export const sleep = (ms: number) => new Promise<any>(resolve => {
  setTimeout(() => {
    resolve();
  }, ms);
});

export const I18N = (language: string) => ({
  t: (textField: string) => I18n.t(textField, { locale: language })
});

export const firstN = (n: number, array: any[]): any[] => array.filter((_, index: number) => index < n);

export const renameKey = (object:any, replacement:any) => {
  _.each(object, function (value, key) {
    key = replacement[key] || key;
    object[key] = value;
  });
  return _.omit(object, Object.keys(replacement))
}

export const groupByKey = (array:any[], key:string) => _.mapValues(_.groupBy(array, key), clist => clist.map(car => _.omit(car, key)))

// Filter Array based on multiples filters option
export const applyMultiFilter = (products, filters) => {
  const filterKeys = Object.keys(filters);
  return products.filter(product => {
      return filterKeys.every(key => {
          if (!filters[key].length) return true;
          // Loops again if product[key] is an array (for material attribute).
          if (Array.isArray(product[key])) {
              return product[key].some(keyEle => filters[key].includes(keyEle));
          }
          return filters[key].includes(product[key]);
      });
  });
}

export const getLastWidhthOrder =(last:number ) =>{
  if(last == 1 ) return [1,2,3,4,5]
  if(last == 2 ) return [2,3,1,4,5]
  if(last == 3 ) return [3,4,2,5,1]
  if(last == 4 ) return [4,5,3,2,1]
  if(last == 5 ) return [5,4,3,2,1]
  else return[]
}

export const generateUuidv4 = (): string => {
  return `10000000-10004000800010000000000`.replace(/[018]/g, (c: string) =>
    (
      Number(c) ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
    ).toString(16)
  );
};
