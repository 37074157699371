import React from "react";
import { StyleSheet, Text, View } from "react-native";
import MeasurementCard from "./MeasurementCard";
import MeasurementScale from "./MeasurementScale";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";

type Props = {
  panelId: string;
  BasicMeasurements: {
    left: {
      length: number | undefined;
      width: number | undefined;
      widthHeel: number | undefined;
      heightInstepPoint: number | undefined;
      widthCategory: number | undefined;
    };
    right: {
      length: number | undefined;
      width: number | undefined;
      widthHeel: number | undefined;
      heightInstepPoint: number | undefined;
      widthCategory: number | undefined;
    };
  };
};

const MeasurementsPanel = ({ panelId, BasicMeasurements }: Props) => {
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [language] = useGlobalState("language");

  const i18n = I18N(language);
  const feetMeasurement: any = {};
  const feetMeasurementKeys = ["length", "width", "widthCategory"];
  const moreMeasurements: any = {};
  const moreMeasurementKeys = ["widthHeel", "heightInstepPoint"];

  feetMeasurementKeys.forEach((measure: any) => {
    if (measure !== "widthCategory")
      feetMeasurement[measure] = {
        name: i18n.t(`MeasurementScale.${measure}`),
        unit: i18n.t("MeasurementScale.cm"),
        values: { left: BasicMeasurements.left[measure], right: BasicMeasurements.right[measure] }
      };
  });

  moreMeasurementKeys.forEach((measurement: any) => {
    moreMeasurements[measurement] = {
      name: i18n.t(`MeasurementScale.${measurement}`),
      unit: i18n.t("MeasurementScale.cm"),
      values: { left: BasicMeasurements.left[measurement], right: BasicMeasurements.right[measurement] }
    };
  });

  const getValues = (key: string) => {
    return {
      ...(currentMeasurements
        ? currentMeasurements.measurements[0].side === "Left"
          ? {
              left: currentMeasurements.measurements[0].categorized_measurements[key].category,
              right: currentMeasurements.measurements[1].categorized_measurements[key].category
            }
          : {
              left: currentMeasurements.measurements[1].categorized_measurements[key].category,
              right: currentMeasurements.measurements[0].categorized_measurements[key].category
            }
        : { left: null, right: null })
    };
  };

  return (
    <View style={styles.panel}>
      {panelId && panelId === "analysis" && (
        <View style={styles.panelContent}>
          <MeasurementScale
            caption={i18n.t("MeasurementScale.foot_type")}
            valueCount={3}
            categories={[{ name: i18n.t("MeasurementScale.flat"), value: 0 }, { name: i18n.t("MeasurementScale.normal"), value: 1 }, { name: i18n.t("MeasurementScale.high"), value: 2 }]}
            values={{
              ...(currentMeasurements
                ? currentMeasurements.measurements[0].side === "Left"
                  ? { left: currentMeasurements.measurements[0].categorized_measurements.foot_type_category, right: currentMeasurements.measurements[1].categorized_measurements.foot_type_category }
                  : { left: currentMeasurements.measurements[1].categorized_measurements.foot_type_category, right: currentMeasurements.measurements[0].categorized_measurements.foot_type_category }
                : { left: null, right: null })
            }}
          />

          <MeasurementScale
            caption={i18n.t("MeasurementScale.heel_angle")}
            valueCount={3}
            categories={[{ name: i18n.t("MeasurementScale.pronation"), value: 0 }, { name: i18n.t("MeasurementScale.neutral"), value: 1 }, { name: i18n.t("MeasurementScale.supination"), value: 2 }]}
            values={getValues("heel_angle")}
          />

          <MeasurementScale
            caption={i18n.t("MeasurementScale.arch_height")}
            valueCount={3}
            categories={[{ name: i18n.t("MeasurementScale.low"), value: 0 }, { name: i18n.t("MeasurementScale.normal"), value: 1 }, { name: i18n.t("MeasurementScale.high"), value: 2 }]}
            values={getValues("arch_height")}
          />
        </View>
      )}
      {panelId && panelId === "measurements" && (
        <View style={styles.panelContent}>
          {feetMeasurement &&
            Object.keys(feetMeasurement).map(measure => (
              <MeasurementCard key={measure} name={feetMeasurement[measure].name} unit={feetMeasurement[measure].unit} values={feetMeasurement[measure].values} />
            ))}
          <MeasurementScale
            caption={i18n.t("MeasurementScale.foot_width")}
            valueCount={5}
            categories={[{ name: i18n.t("MeasurementScale.narrow"), value: 0 }, { name: i18n.t("MeasurementScale.wide"), value: 4 }]}
            values={{ left: BasicMeasurements.left.widthCategory - 1, right: BasicMeasurements.right.widthCategory - 1 }}
          />
        </View>
      )}
      {panelId && panelId === "more" && (
        <View style={styles.panelContent}>
          {moreMeasurements &&
            Object.keys(moreMeasurements).map(measure => (
              <MeasurementCard key={measure} name={moreMeasurements[measure].name} unit={moreMeasurements[measure].unit} values={moreMeasurements[measure].values} />
            ))}
        </View>
      )}
    </View>
  );
};

export default MeasurementsPanel;

const styles = StyleSheet.create({
  panel: {
    height: "100%"
  },
  panelContent: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  }
});
